.dataTable {
  width: 100%;
  border-collapse: collapse;
}

.dataTable thead th {
  background: linear-gradient(90deg, #ede5e8, #b5c4c5);
  border: 1px solid #e800ff;
  color: #27427d;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.22, 1.37);
}

.dataTable thead th:hover {
  background: linear-gradient(90deg, #c0cd57, #b699db);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dataTable tbody td {
  padding: 8px 15px;
  border: 1px solid #ddd;
}
.inputHeight {
  height: 28px;
}
.deleteBtnCentre {
  padding-left: 86px !important;
}
.inputBoxTop {
  padding-top: 6px !important;
}
.tableTop {
  margin-top: 18px;
}
.row {
  display: flex;
  align-items: center; /* Optional: To align items vertically in the center */
  gap: 10px; /* Optional: To give a gap between the two elements */
}
