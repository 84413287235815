@tailwind base;
@tailwind components;
@tailwind utilities;
.rdt_Table .rdt_TableRow:hover {
  background-color: #aaa;
  color: #fff;
}

.rdt_TableCell {
  border-width: 0.1px;
  border-style: solid;
  border-color: #f9f4f4;
}
.lesspad {
  padding: 0.3rem;
}
.redCol {
  background-color: burlywood;
}
.right-align {
  text-align: right;
}
.fixed-width-button {
  /* Set your desired width */
  width: 200px;
}
.flexible-width-button {
  /* Set your desired width */
  width: 100%;
}
.sidebar-height {
  /* Set your desired width */
  width: 1000px;
}
